<template>
  <v-card>
    <v-card-title>
      Admin Landing
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="tenants"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Tenant List</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog
              v-model="dialog"
              max-width="500px"
            >
              <v-card>
                <v-card-title>
                  <span class="headline">Edit Tenant</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          v-model="editedItem.firstName"
                          label="First Name"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          v-model="editedItem.lastName"
                          label="Last Name"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          v-model="editedItem.userName"
                          label="Username"
                        ></v-text-field>
                      </v-col>

                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="getTenants"
          >
            Reset
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { DB } from '../firebase/db'
import { mapState } from 'vuex'
import { Functions } from '../firebase/functions'

const deleteUser = Functions.httpsCallable('deleteUser')

export default {
  name: 'AdminView',
  data(){
    return {
      tenants: [],
      headers: [
        {
          text: 'First Name',
          align: 'start',
          sortable: false,
          value: 'firstName',
        },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Email', value: 'email' },
        { text: 'Username', value: 'userName' },
        { text: 'uid', value: 'uid' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        email: '',
        userName: '',
        uid: '',
      },
      defaultItem: {
        name: '',
        email: '',
        userName: '',
        uid: '',
      },
    }
  },
  components: {},
  computed: {
    ...mapState(['user']),
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  methods: {
    getTenants() {
      this.tenants = []
      DB.collection('user').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.tenants.push({
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            email: doc.data().email,
            userName: doc.data().userName,
            uid: doc.data().uid
          })
        })
      })
    },

    editItem (item) {
      this.editedIndex = this.tenants.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true

      // console.log('this.editedIndex: ', this.editedIndex)
      // console.log('this.editedItem: ', this.editedItem)
    },

    deleteItem (item) {
      this.editedIndex = this.tenants.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {      
      let tenantAccountRef = DB.collection("user").doc(this.editedItem.uid)

      deleteUser({
        email: this.editedItem.email,
        uid: this.editedItem.uid,
      }).then(result => {
        // console.log('result.data: ', result.data)
        if (result.data.deleted) {
          tenantAccountRef
            .delete()
            .then(() => {
              alert(result.data.message)
              this.getTenants()
            })
        } else {
          alert(result.data.message)
        }
      })

      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      // console.log('this.editedItem.uid: ', this.editedItem.uid)
      let tenantAccountRef = DB.collection("user").doc(this.editedItem.uid)

      tenantAccountRef
        .update({
          userName: this.editedItem.userName,
          firstName: this.editedItem.firstName,
          lastName: this.editedItem.lastName,
        })

      this.getTenants()
      this.close()
    },

    reloadCustomerComponent() {
      this.$store.dispatch('iterateKey')
    },

    loggedIn() {
      this.$store.dispatch('loggedIn')
    }
  },
  mounted() {
    this.getTenants()
  }
}
</script>

<style scoped>

</style>