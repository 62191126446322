<template>
  <v-container 
    style="
      padding: 0;
      margin-top: 122px;
    " 
    fluid
  >
    <status-bar></status-bar>
    
    <v-container>
      <v-row v-if="user.email" align="center" justify="space-around">
        <v-col v-if="!user.emailVerified" cols="12">
          <verify-email></verify-email>
        </v-col>
        <v-col v-if="user.emailVerified" cols="12">
          <create-admin></create-admin>
          <!-- <v-card>
            <v-card-title>
              This is the Admin View Landing
            </v-card-title>
            <v-card-text>
              
              <v-data-table
                :headers="headers"
                :items="tenants"
                :items-per-page="5"
                class="elevation-1"
              ></v-data-table>

            </v-card-text>
          </v-card> -->
          <tenant-list></tenant-list>
        </v-col>
      </v-row>
    </v-container>

    <Footer></Footer>

  </v-container>
</template>

<script>
import CreateAdmin from '@/components/CreateAdmin.vue'
import VerifyEmail from '@/components/VerifyEmail.vue'
import TenantList from '@/components/TenantList.vue'
import StatusBar from '@/components/StatusBar.vue'
import Footer from '@/components/Footer.vue'

import { DB } from '../firebase/db'
import { mapState } from 'vuex'

export default {
  name: 'AdminView',
  data(){
    return {
      tenants: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Email', value: 'email' },
        { text: 'Username', value: 'userName' },
        { text: 'uid', value: 'uid' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        email: '',
        userName: '',
        uid: '',
      },
      defaultItem: {
        name: '',
        email: '',
        userName: '',
        uid: '',
      },
    }
  },
  components: {
    VerifyEmail,
    CreateAdmin,
    TenantList,
    StatusBar,
    Footer,
  },
  computed: {
    ...mapState(['user']),
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  methods: {
    getTenants() {
      DB.collection('user').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.tenants.push({
            name: doc.data().firstName + ' ' + doc.data().lastName,
            email: doc.data().email,
            userName: doc.data().userName,
            uid: doc.data().uid
          })
          // ["firstName", "lastName", "email", "userName", "emailVerified", "uid"]
        })
      })
    },
    loggedIn() {
      this.$store.dispatch('loggedIn')
    }
  },
  mounted() {
    this.getTenants()
  }
}
</script>

<style scoped>

</style>